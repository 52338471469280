<template>
  <div id="billList">
    <Back title="应收列表"></Back>
    <!-- 搜索 -->
    <van-search v-model="kw" placeholder="请输入搜索关键词" show-action>
      <template #action>
        <div @click="onKeyWord">确认搜索</div>
      </template>
    </van-search>
    <!-- 下拉菜单 -->
    <van-dropdown-menu>
      <van-dropdown-item
        v-model="exacctIdSet"
        :options="billTypeOpt"
        @change="onBillType"
      />
      <van-dropdown-item
        v-model="billStatusSet"
        :options="billStatusOpt"
        @change="onBillStatus"
      />
    </van-dropdown-menu>
    <!-- 下拉列表 -->
    <main>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <section class="block-item" v-for="item in billList" :key="item.billId">
          <div class="block-title van-hairline--bottom">
            <div class="title-left"><i></i>{{ item.tenantName }}</div>
            <div class="title-right" @click="linkTo(item.billId)">查看详情</div>
          </div>
          <div class="block-content">
            <p>
              <span>房源：</span
              >{{ item.unitName }}-{{ item.resourceNames }}
            </p>
            <p>
              <span>费用科目：</span<em>{{ item.exacctName }}
            </p>
            <p>
              <span>费用：</span>￥{{ item.billAmount }}
            </p>
            <p>
              <span>应收日期：</span>
              {{ formatterDate(item.billDate, "yyyy-MM-dd") }}
            </p>
            <p>
              <span>收款状态：</span>
              {{ billStatusObj[item.billStatus] }}
            </p>
          </div>
        </section>
      </van-list>
    </main>
  </div>
</template>

<script>
import Back from "@/components/back";
import { getBillList } from "@/api/bill/bill";
import { billTypeOpt, billStatusOpt, billStatusObj } from "@/db/bill";
import { formatterDate } from "@/utils/utils";
import { Toast } from "vant";
export default {
  components: { Back },
  data() {
    return {
      billTypeOpt,
      billStatusOpt,
      billStatusObj,
      formatterDate,
      // 参数
      pageNo: 0,
      exacctIdSet: "", //费用科莫
      billStatusSet: "0", //收款类型
      kw: "",
      // 列表
      billList: [],
      loading: false,
      finished: false,
    };
  },
  methods: {
    // 【请求】账单应收列表
    getBillList() {
      let exacctIdSet = this.exacctIdSet;
      let billStatusSet = this.billStatusSet;
      if (exacctIdSet == "") {
        exacctIdSet = [];
      } else {
        exacctIdSet = [exacctIdSet];
      }
      if (billStatusSet == "") {
        billStatusSet = [];
      } else {
        billStatusSet = [billStatusSet];
      }
      let data = {
        kw: this.kw,
        pageSize: 10,
        pageNo: this.pageNo,
        billStatusSet, //费用科目
        exacctIdSet, //收款类型
      };
      getBillList(data)
        .then((res) => {
          if (res.data) {
            this.billList = [...this.billList, ...res.data.content];
            this.loading = false;
            if (this.billList.length >= res.data.total) {
              this.finished = true;
            }
          } else {
            this.loading = false; // 本次加载结束
            this.finished = true; //加载完了
          }
        })
        .catch((err) => {
          Toast(err);
        });
    },
    // 下拉加载
    onLoad() {
      this.pageNo++;
      this.getBillList();
    },
    // 【监听】费用科目
    onBillType() {
      this.billList = [];
      this.pageNo = 1;
      this.finished = false;
      this.getBillList();
    },

    // 【监听】收款类型
    onBillStatus() {
      this.billList = [];
      this.pageNo = 1;
      this.finished = false;
      this.getBillList();
    },

    // 【监听】关键字查询
    onKeyWord() {
      this.billList = [];
      this.pageNo = 1;
      this.finished = false;
      this.getBillList();
    },

    // 监听跳转
    linkTo(billId) {
      this.$router.push({
        path: "/bill/billInfo",
        query: { billId },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/theme";
#billList {
  height: 100%;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
  overflow: auto;
  border-left: 0.15rem solid $bg-color;
  border-right: 0.15rem solid $bg-color;
}

// 文本模块
.block-item {
  border-top: $bg-color 0.15rem solid;
  display: flex;
  flex-direction: column;
  padding: 0 0.16rem 0.16rem 0.16rem;
  box-sizing: border-box;
  > .block-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.1rem 0;
    box-sizing: border-box;
    .title-left {
      display: flex;
      align-items: center;
      > i {
        display: inline-block;
        background: $theme-color;
        width: 0.04rem;
        height: 0.15rem;
        border-radius: 0 0.04rem 0.04rem 0;
        margin-right: 0.1rem;
      }
    }
    .title-right {
      color: $theme-color;
    }
  }
}

.block-content {
  padding-top: 0.1rem;
  > p {
    display: flex;
    justify-content: space-between;
    font-size: 0.14rem;
    > span {
      color: #999;
    }
  }
}
</style>